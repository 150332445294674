/* General styles */
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f1f1f1;
  height: 100%;
}

.dashboard-container {
  position: relative;
  display: flex;
  height: 100vh;
  align-items: stretch;
}

.main {
  font-family: sans-serif;
  padding: 0px;
}

.sidebar {
  flex: 0.22;
  background-color: #fff;
  color: black;
  padding: 16px;
  font-family: sans-serif;
}

.sidebar h2 {
  margin-bottom: 16px;
  font-size: 24px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  vertical-align: bottom;
}

.sidebar li {
  padding: 8px 16px;
  font-size: 18px;
  cursor: pointer;
}

.sidebar li:hover {
  background-color: grey;
}

.sidebar li.active {
  background-color: grey;
}

.main-content {
  flex: 1;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  font-family: sans-serif;
}


div > div {
  margin-bottom: 16px;
  padding: 16px;
}

.sign-out-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  color: grey;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}

.sign-out-button:hover {
  background-color: rgb(108, 108, 108);
  color: white;
}