.rasterview {
    width: 100%; 
    height: auto;  
}

.image-results {
    display: flex;
}

.tiff-info {
    background-color: #ffffffe4;
    padding: 10px;
    border-radius: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: max-content;
    text-align: center;
    display:block;
    margin:auto;
}