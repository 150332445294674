.canvas {
    height: 450px;
    width: 80%;
    padding: 0px;
    justify-self: center;
}

.model {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
}

.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.whole {
  height: 700px;
}

.download-button {
  color: grey;
  background-color: white;
}

.button-object {
  color: white;
  background-color: grey;
}

.button-object:hover {
  background-color: rgb(108, 108, 108);
}

