.input {
    margin-left: 0px;
}

.inputs {
    text-align: center;
    display: grid;
    place-items: center;
}

.fullimg {
    width: 40%;
    height: auto;
}

.plot {
    text-align: center;
}

.display-button {
    background-color: grey;
    color: white;
}

.display-button:hover {
    background-color: rgb(108, 108, 108);
  }

