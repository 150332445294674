/* login-page.css */

/* General styles */


body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f1f1f1;
    background-image: url('./logo-background.png');
  }
  
  .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-form {
    background-color: #2e2e2e;
    width: 50%;
    height: 100%;
    opacity: 0.9;
  }
  
  h2 {
    margin-top: 0;
    margin-bottom: 16px;
    text-align: center;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #aea8a8;
    border-radius: 4px;
    width: 50%;
  }
  
  button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }

  .coolant-logo {
    width: 150px;
    height: auto;
    display:block;
    margin:auto;
    padding: 50px;
}

.login-entries {
  text-align: center;
}
  
  /* Additional styles for responsiveness (optional) */

  
  @media screen and (max-width: 400px) {
    .login-form {
      width: 90%;
    }
  }

  .words {
      flex-shrink: 0;
      width: 1px;
      height: auto; /* 72px */
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;
      overflow: hidden;
      flex: 1 0 0px;
      position: relative;
      opacity: 0.9;
      font-weight: 400;
      font-style: normal;
      font-family: "Outfit", "Outfit Placeholder", sans-serif;
      color: #ffffff;
      font-size: 24px;
      letter-spacing: 0em;
      line-height: 1.5;
      text-align: center;
  }

  .login-words  {
    flex-shrink: 0;
    width: 380px;
    height: auto;
    display:block;
    margin:auto;
    max-width: 100%;
    position: relative;
    font-weight: 500;
    font-style: normal;
    font-family: "Inter", "Inter Placeholder", sans-serif;
    color: #ffffff;
    font-size: 24px;
    line-height:1.2;
    text-align: center;
  }

.login-button {
  background-color:#2e2e2e;
  color: grey;
  width: 100px;
}

.login-button:hover {
  background-color: rgb(108,108,108);
}